import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsSelectPlainAllBasicPreview from "../../../../library/components/form-elements/select/react/_preview/plain"
import FormElementsSelectRoundedAllBasicPreview from "../../../../library/components/form-elements/select/react/_preview/rounded"

import HeroSelectComponent from "../../../../library/components/form-elements/select/react/_preview/heroSelectComponent"

// *** Form Element Select Plain ***//

// Base
const FormElementsSelectPlainBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/plain/base/basic.jsx")
const FormElementsSelectPlainBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/plain/base/basic.html")

const FormElementsSelectPlainBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/plain/base/helper_text.jsx")
const FormElementsSelectPlainBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/plain/base/helper_text.html")

const FormElementsSelectPlainBaseDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/plain/base/disabled.jsx")
const FormElementsSelectPlainBaseDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/plain/base/disabled.html")

// Lg
const FormElementsSelectPlainLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/plain/lg/basic.jsx")
const FormElementsSelectPlainLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/plain/lg/basic.html")

const FormElementsSelectPlainLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/plain/lg/helper_text.jsx")
const FormElementsSelectPlainLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/plain/lg/helper_text.html")

const FormElementsSelectPlainLgDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/plain/lg/disabled.jsx")
const FormElementsSelectPlainLgDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/plain/lg/disabled.html")

// *** Form Element Select Rounded ***//

// Base
const FormElementsSelectRoundedBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/rounded/base/basic.jsx")
const FormElementsSelectRoundedBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/rounded/base/basic.html")

const FormElementsSelectRoundedBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/rounded/base/helper_text.jsx")
const FormElementsSelectRoundedBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/rounded/base/helper_text.html")

const FormElementsSelectRoundedBaseDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/rounded/base/disabled.jsx")
const FormElementsSelectRoundedBaseDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/rounded/base/disabled.html")

// Lg
const FormElementsSelectRoundedLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/rounded/lg/basic.jsx")
const FormElementsSelectRoundedLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/rounded/lg/basic.html")

const FormElementsSelectRoundedLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/rounded/lg/helper_text.jsx")
const FormElementsSelectRoundedLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/rounded/lg/helper_text.html")

const FormElementsSelectRoundedLgDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/select/react/rounded/lg/disabled.jsx")
const FormElementsSelectRoundedLgDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/select/html/rounded/lg/disabled.html")

export default function FormElementSelectMenusPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsSelectPlainAllBasic",
      title: "Basic select",
      active_tab: 1,
    },
    {
      component_name: "FormElementsSelectRoundedAllBasic",
      title: "Outline",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Select Menus - WindUI Component Library"
        ogtitle="Tailwind CSS Select Menus - WindUI Component Library"
        description="Select menu components represent a control that provides a menu of options. Built with Tailwind CSS by WindUI."
        ogdescription="Select menu components represent a control that provides a menu of options. Built with Tailwind CSS by WindUI."
        url="components/form-elements/select-menus/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Select Menus, Select Menus, Select Menu components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Select Menus</h1>
          <p>
            The select component represents a control that provides a menu of
            options.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <HeroSelectComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Select Plain Basic Section */}
          <h3 id="FormElementsSelectPlainAllBasic">Basic Select</h3>

          <PreviewBlock
            id="FormElementsSelectPlainAllBasic"
            HtmlComponent={FormElementsSelectPlainBaseBasicHTML.default}
            JsxComponent={FormElementsSelectPlainBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsSelectPlainAllBasicPreview
                FormElementsSelectPlainBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSelectPlainBaseBasicHTML.default
                    : FormElementsSelectPlainBaseBasicJsx.default
                }
                FormElementsSelectPlainBaseHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSelectPlainBaseHelperTextHTML.default
                    : FormElementsSelectPlainBaseHelperTextJsx.default
                }
                FormElementsSelectPlainBaseDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSelectPlainBaseDisabledHTML.default
                    : FormElementsSelectPlainBaseDisabledJsx.default
                }
                FormElementsSelectPlainLgBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSelectPlainLgBasicHTML.default
                    : FormElementsSelectPlainLgBasicJsx.default
                }
                FormElementsSelectPlainLgHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSelectPlainLgHelperTextHTML.default
                    : FormElementsSelectPlainLgHelperTextJsx.default
                }
                FormElementsSelectPlainLgDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSelectPlainLgDisabledHTML.default
                    : FormElementsSelectPlainLgDisabledJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Select Rounded Basic Section */}
          <h3 id="FormElementsSelectRoundedAllBasic">Outlined Select</h3>

          <PreviewBlock
            id="FormElementsSelectRoundedAllBasic"
            HtmlComponent={FormElementsSelectRoundedBaseBasicHTML.default}
            JsxComponent={FormElementsSelectRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsSelectRoundedAllBasicPreview
                FormElementsSelectRoundedBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSelectRoundedBaseBasicHTML.default
                    : FormElementsSelectRoundedBaseBasicJsx.default
                }
                FormElementsSelectRoundedBaseHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSelectRoundedBaseHelperTextHTML.default
                    : FormElementsSelectRoundedBaseHelperTextJsx.default
                }
                FormElementsSelectRoundedBaseDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSelectRoundedBaseDisabledHTML.default
                    : FormElementsSelectRoundedBaseDisabledJsx.default
                }
                FormElementsSelectRoundedLgBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSelectRoundedLgBasicHTML.default
                    : FormElementsSelectRoundedLgBasicJsx.default
                }
                FormElementsSelectRoundedLgHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSelectRoundedLgHelperTextHTML.default
                    : FormElementsSelectRoundedLgHelperTextJsx.default
                }
                FormElementsSelectRoundedLgDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSelectRoundedLgDisabledHTML.default
                    : FormElementsSelectRoundedLgDisabledJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use the select component to create drop-down select menus so that
            users can select the value they want.{" "}
          </p>
          <p>
            The select tag normally goes within a form element, with the items
            to choose from coded within another tag, <code>&lt;option&gt;</code>
            . It can also be a standalone element, which would still be
            associated with a form with one of its special attributes, form.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Selects are created using the native&nbsp;
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox"
              target="_blank"
            >
              markup
            </a>
            .
          </p>

          <p>
            with additional tailwind classes applied. They come in two basic
            styles (<strong>base</strong> and <strong>rounded</strong>) and 2
            sizes:
          </p>
          <ul>
            <li>
              <strong>Base:</strong> select font size of 0.875rem (14px) height
              of 2.5rem (40px) and a label that scales from 0.875rem (14px) in
              font size, when the select is empty or 0.75rem (12px) when the
              label is floated. Helper text is also 0.75rem (12px) and a top and
              bottom margin of 1.5rem (24px) is used to house both the floating
              label and the helper text.
            </li>
            <li>
              <strong>Large:</strong> select font size of 1rem (16px) height of
              3rem (48px) and a label that scales from 1rem (16px) in font size,
              when the select is empty or 0.75rem (12px) when the label is
              floated. Helper text is also 0.75rem (12px) and a top and bottom
              margin of 1.5rem (24px) is used to house both the floating label
              and the helper text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Since selects are built with the native <code>&lt;select&gt;</code>{" "}
            element no additional accessibility roles are required.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
