import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsSelectAllRoundedPreview(props) {
  const {
    FormElementsSelectRoundedBaseBasic,
    FormElementsSelectRoundedBaseHelperText,
    FormElementsSelectRoundedBaseDisabled,
  } = props

  const {
    FormElementsSelectRoundedLgBasic,
    FormElementsSelectRoundedLgHelperText,
    FormElementsSelectRoundedLgDisabled,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="flex w-full flex-col gap-6 md:w-60">
          <h3 className="font-medium text-slate-500">Base size:</h3>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectRoundedBaseBasic}
              componentName="FormElementsSelectRoundedBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Rounded base basic select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-04"
                  name="id-04"
                  required
                  className="peer relative h-10 w-full appearance-none rounded border border-slate-200 bg-white px-4 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-04"
                  className="pointer-events-none absolute top-2.5 left-2 z-[1] px-2 text-sm text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-2.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-04 description-04"
                  role="graphics-symbol"
                >
                  <title id="title-04">Arrow Icon</title>
                  <desc id="description-04">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/*      <!-- End Rounded base basic select --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectRoundedBaseHelperText}
              componentName="FormElementsSelectRoundedBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Rounded base select with helper text --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-05"
                  name="id-05"
                  required
                  className="peer relative h-10 w-full appearance-none rounded border border-slate-200 bg-white px-4 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-05"
                  className="pointer-events-none absolute top-2.5 left-2 z-[1] px-2 text-sm text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-2.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-05 description-05"
                  role="graphics-symbol"
                >
                  <title id="title-05">Arrow Icon</title>
                  <desc id="description-05">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*      <!-- End Rounded base select with helper text --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectRoundedBaseDisabled}
              componentName="FormElementsSelectRoundedBaseDisabled"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Rounded base disabled select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-06"
                  name="id-06"
                  required
                  disabled
                  className="peer relative h-10 w-full appearance-none rounded border border-slate-200 px-4 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-06"
                  className="pointer-events-none absolute top-2.5 left-2 z-[1] px-2 text-sm text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-2.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-06 description-06"
                  role="graphics-symbol"
                >
                  <title id="title-06">Arrow Icon</title>
                  <desc id="description-06">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*      <!-- End Rounded base disabled select --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="flex w-full flex-col gap-6 md:w-60">
          <h3 className="font-medium text-slate-500">Large size:</h3>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectRoundedLgBasic}
              componentName="FormElementsSelectRoundedLgBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large basic select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-10"
                  name="id-10"
                  required
                  className="peer relative h-12 w-full appearance-none rounded border border-slate-200 bg-white px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-10"
                  className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-10 description-10"
                  role="graphics-symbol"
                >
                  <title id="title-10">Arrow Icon</title>
                  <desc id="description-10">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/*    <!-- End Rounded large basic select --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectRoundedLgHelperText}
              componentName="FormElementsSelectRoundedLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large select with helper text --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-11"
                  name="id-11"
                  required
                  className="peer relative h-12 w-full appearance-none rounded border border-slate-200 bg-white px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-11"
                  className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-11 description-11"
                  role="graphics-symbol"
                >
                  <title id="title-11">Arrow Icon</title>
                  <desc id="description-11">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*    <!-- End Rounded large select with helper text --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectRoundedLgDisabled}
              componentName="FormElementsSelectRoundedLgDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large disabled select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-12"
                  name="id-12"
                  required
                  disabled
                  className="peer relative h-12 w-full appearance-none rounded border border-slate-200 px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-12"
                  className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-12 description-12"
                  role="graphics-symbol"
                >
                  <title id="title-12">Arrow Icon</title>
                  <desc id="description-12">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*    <!-- End Rounded large disabled select --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
