import React from "react"

export default function HeroSelectComponent() {
  return (
    <>
      {/*<!-- Plain basic select list --> */}
      {/*<!-- base sized --> */}
      <div className="relative my-6 w-60">
        <select
          id="id-11h"
          name="id-11h"
          required
          className="relative w-full h-12 px-4 transition-all bg-white border rounded outline-none appearance-none peer border-slate-200 text-slate-500 autofill:bg-white focus:border-emerald-500 focus:outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
        >
          <option value="" disabled selected></option>
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
          <option value="3">Option 3</option>
        </select>

        <label
          htmlFor="id-11h"
          className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
        >
          Select an option
        </label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-labelledby="title-11h description-11h"
          role="graphics-symbol"
        >
          <title id="title-11h">Arrow Icon</title>
          <desc id="description-11h">Arrow icon of the select list.</desc>
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <small className="absolute flex justify-between w-full px-4 py-1 text-xs transition text-slate-400">
          <span>Text field with helper text</span>
        </small>
      </div>
    </>
  )
}
