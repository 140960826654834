import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsSelectAllPlain(props) {
  const {
    FormElementsSelectPlainBaseBasic,
    FormElementsSelectPlainBaseHelperText,
    FormElementsSelectPlainBaseDisabled,
  } = props

  const {
    FormElementsSelectPlainLgBasic,
    FormElementsSelectPlainLgHelperText,
    FormElementsSelectPlainLgDisabled,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row">
        <div className="flex w-full flex-col gap-6 md:w-60">
          <h3 className="font-medium text-slate-500">Base size:</h3>
          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectPlainBaseBasic}
              componentName="FormElementsSelectPlainBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain base basic select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-01"
                  name="id-01"
                  required
                  className="peer relative h-10 w-full appearance-none border-b border-slate-200 bg-white px-4 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>

                <label
                  for="id-01"
                  className="pointer-events-none absolute top-2.5 left-2 z-[1] px-2 text-sm text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-2.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-01 description-01"
                  role="graphics-symbol"
                >
                  <title id="title-01">Arrow Icon</title>
                  <desc id="description-01">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/*    <!-- End Plain base basic select --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectPlainBaseHelperText}
              componentName="FormElementsSelectPlainBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Plain base select with helper text --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-02"
                  name="id-02"
                  required
                  className="peer relative h-10 w-full appearance-none border-b border-slate-200 bg-white px-4 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-02"
                  className="pointer-events-none absolute top-2.5 left-2 z-[1] px-2 text-sm text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-2.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-02 description-02"
                  role="graphics-symbol"
                >
                  <title id="title-02">Arrow Icon</title>
                  <desc id="description-02">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*      <!-- End Plain base select with helper text --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectPlainBaseDisabled}
              componentName="FormElementsSelectPlainBaseDisabled"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Plain base disabled select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-03"
                  name="id-03"
                  required
                  disabled
                  className="peer relative h-10 w-full appearance-none border-b border-slate-200 px-4 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-03"
                  className="pointer-events-none absolute top-2.5 left-2 z-[1] px-2 text-sm text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-2.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-03 description-03"
                  role="graphics-symbol"
                >
                  <title id="title-03">Arrow Icon</title>
                  <desc id="description-03">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*      <!-- End Plain base disabled select --> */}
            </CopyComponent>
          </div>
        </div>

        <div className="flex w-full flex-col gap-6 md:w-60">
          <h3 className="font-medium text-slate-500">Large size:</h3>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectPlainLgBasic}
              componentName="FormElementsSelectPlainLgBasic"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Plain large basic select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-07"
                  name="id-07"
                  required
                  className="peer relative h-12 w-full appearance-none border-b border-slate-200 bg-white px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-07"
                  className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-07 description-07"
                  role="graphics-symbol"
                >
                  <title id="title-07">Arrow Icon</title>
                  <desc id="description-07">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/*      <!-- End Plain large basic select --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectPlainLgHelperText}
              componentName="FormElementsSelectPlainLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Plain large select with helper text --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-08"
                  name="id-08"
                  required
                  className="peer relative h-12 w-full appearance-none border-b border-slate-200 bg-white px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-08"
                  className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-08 description-08"
                  role="graphics-symbol"
                >
                  <title id="title-08">Arrow Icon</title>
                  <desc id="description-08">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*      <!-- End Plain large select with helper text --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsSelectPlainLgDisabled}
              componentName="FormElementsSelectPlainLgDisabled"
              extraClasses="copy-block-container"
            >
              {/*      <!-- Component: Plain large disabled select --> */}
              <div className="relative my-6 md:w-60">
                <select
                  id="id-09"
                  name="id-09"
                  required
                  disabled
                  className="peer relative h-12 w-full appearance-none border-b border-slate-200 px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                >
                  <option value="" disabled selected></option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
                <label
                  for="id-09"
                  className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Select an option
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-labelledby="title-09 description-09"
                  role="graphics-symbol"
                >
                  <title id="title-09">Arrow Icon</title>
                  <desc id="description-09">
                    Arrow icon of the select list.
                  </desc>
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition">
                  <span>Text field with helper text</span>
                </small>
              </div>
              {/*      <!-- End Plain large disabled select --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
